import React from "react";
import { Box } from "@mui/material"

export const Thermostat = () => {
    return (
        <Box>
            <h2>Project Thermostaat</h2>

            <p>De warmtepomp bleek een simpele aan/uit-thermostaat te vereisen. Omdat dat eenvoudig met een relais geregeld is, besloot ik om ons eigen thermostaatsysteem te bouwen.</p>
            <p>De thermostaat is per ruimte op temperatuur te stellen, als er een warmtevraag is worden de kleppen van de vloerverwarming opengezet.</p>
        </Box>
    );
}