import React from 'react';
import logo from './logo.svg';
import './css/main.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { About } from './pages/About';
import { Main } from './pages/Main';
import { TopNavigator } from './components/TopNavigator';
import { Renovation } from './pages/Renovation';
import { CatDoor } from './pages/projects/CatDoor';
import { Thermostat } from './pages/projects/Thermostat';
import { AppBar } from '@mui/material';
import { ResponsiveAppBar } from './components/AppBar';

function App() {
  return (
    <div className="App">

      <Router>
        <div>
          <TopNavigator/>
          <Routes>
            <Route path="/about" element={<About />}/>
            <Route path="/" element={<Main />}/>
            <Route path="/renovation" element={<Renovation />}/>
            <Route path="/projects/catdoor" element={<CatDoor />}/>
            <Route path="/projects/thermostat" element={<Thermostat />}/>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
